<template>
  <div>
    <VTitle :title="$t('app.attestations', 2)" class="section-title" />

    <VList
      hide-header
      :headers="headers"
      :rows="data"
      :is-loading="isLoading"
      :expanded-indexes="expandedIndexes"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image="{ item }">
        <VImage
          :color="item?.attestation?.colour"
          :name="getText(item?.attestation?.texts, 'name')"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.attestation="{ item }">
        {{ getText(item?.attestation?.texts, "name") }}

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          {{ $t("app.submit_by") }} {{ $t(`app.${item.submit_by}`) }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.training="{ item }">
        {{ getText(item?.registration?.training?.texts, "name") }}

        <div class="version mt-0.5">
          {{ $t("app.versions") }} {{ item.registration?.training?.version }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.result="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${item.result}`)"
            :class="getStatusColor(item.result)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${item.status}`)"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #header.__ACTIONS="{ index }">
        <div class="flex justify-center">
          <VAction
            :icon="isExpandedAll ? 'collapse-all' : 'expand-all'"
            @click="onClickToggleExpandAll(data.length)"
          />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item, index }">
        <div class="flex justify-center">
          <VAction
            :icon="expandedIndexes.includes(index) ? 'collapse' : 'expand'"
            @click="onClickToggleExpand(index)"
          />
        </div>
      </template>

      <template #expanded-item="{ item }">
        <div class="grid grid-cols-2 gap-4 p-3">
          <VLine :label="$t('app.files', 2)">
            <template #value>
              <FileItems :files="item.files" />
            </template>
          </VLine>

          <VLine
            :label="$t('app.requested_on')"
            :value="formatDate({ date: item.requested_on })"
          />

          <VLine
            :label="$t('app.learner_comments')"
            :value="item.learner_comments"
          />

          <VLine
            :label="$t('app.count_towards_maximum')"
            :value="item.count_towards_maximum"
          />

          <VLine
            :label="$t('app.instructor_comments')"
            :value="item.instructor_comments"
          />

          <div class="col-span-2 border-b border-gray-200 w-full" />

          <div class="col-span-2">
            <VLine :label="$t('app.submitted_by')" class="mb-2" />
            <VAvatar :item="item.submitter" />
          </div>

          <VLine
            :label="$t('app.submit_by')"
            :value="$t(`app.${item.submit_by}`)"
          />

          <VLine
            :label="$t('app.submitted_on')"
            :value="formatDate({ date: item.submitted_on })"
          />

          <div class="col-span-2 border-b border-gray-200 w-full" />

          <div class="col-span-2">
            <VLine :label="$t('app.approved_by')" class="mb-2" />
            <VAvatar :item="item.approver" />
          </div>

          <VLine
            :label="$t('app.approve_by')"
            :value="$t(`app.${item.approve_by}`)"
          />

          <VLine
            :label="$t('app.approved_on')"
            :value="formatDate({ date: item.approved_on })"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useToggleExpand from "@/composables/useToggleExpand";
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import VAvatar from "@/components/VAvatar";
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VAction from "@/components/tables/VAction";
import VLine from "@/components/VLine";
import FileItems from "@/components/FileItems";

export default {
  components: {
    VAvatar,
    VTitle,
    VList,
    VImage,
    VChip,
    FileItems,
    VAction,
    VLine
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { formatDate } = useDisplay();
    const { getStatusColor } = useColor();
    const {
      expandedIndexes,
      isExpandedAll,
      onClickToggleExpand,
      onClickToggleExpandAll
    } = useToggleExpand();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.attestations"),
        value: "attestation"
      },
      {
        text: t("app.trainings"),
        value: "training"
      },
      {
        text: t("app.result"),
        value: "result",
        headerClass: "justify-center"
      },
      {
        text: t("app.status"),
        value: "status"
      },
      {
        text: t("app.actions"),
        value: "__ACTIONS"
      }
    ];

    // Data
    const data = ref([]);
    const isLoading = ref(false);

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.attestations", 2)} - ${t("app.learners", 2)} - ${t("app.teaching")}`;

      const { firstname, lastname } = props.resource?.firstname ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "teaching.learners.attestations",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? [];
      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      headers,
      data,
      isLoading,
      // useToggleExpand
      expandedIndexes,
      isExpandedAll,
      onClickToggleExpand,
      onClickToggleExpandAll,
      // useTexts
      getText,
      // useDisplay
      formatDate,
      // useColor
      getStatusColor
    };
  }
};
</script>
